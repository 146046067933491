#lessons-purchase .card-profile {
  margin-top: 75px;
  text-align: center;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);

  &_body {
    display: block;
    cursor: pointer;
  }

  p {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 0 8px 12px 0 #0000001a;
    background: #F1F2F6;
  }

  .btn-secondary {
    background: #fff !important;
    border: 2px solid #000;
    color: #000 !important;
  }
  .btn-primary {
      color: #FFF;
      background: #CD2418;
  }

  .card-cover {
    height: 130px;
    background-position: center center;
    background-size: cover;
    border-radius: $border-radius-extreme $border-radius-extreme 0 0;
  }

  &_location {
    text-align: left;
    margin-bottom: 10px;
    color: #000;
    display: flex;
    &_icon {
      height: 16px;
      margin-right: 5px;
      margin-top: 2px;
      background-image: url("https://d3tz2xd2453h51.cloudfront.net/location-icon.svg");
      width: 22px;
      background-repeat: no-repeat;
    }
  }

  .card-body {
    .card-title {
      margin-top: 5px !important;
    }
    .card-category {
      margin-bottom: 5px;
      text-align: left;
      color: #47495A !important;
      @media screen and (max-width: 991px) {
         
        margin-top: 0;      
      }
    }

    .shorten-bio {
      height: 70px;
    }

    .instrument-list {
      display: flex;
      flex-wrap: wrap;
      max-height: 40px;
      overflow: hidden;
      .instrument-item {
        font-size: 14px;
        font-style: italic;
        font-weight: 500;
        margin-right: 5px;
        &::after {
          content: ',';
        }
      }
      span:last-of-type {
        &::after {
          content: none;
        }
      }
    }
    
  }
  .card-footer {
    padding: 0 15px 20px;
  }

  .card-avatar {
    max-width: 148px;
    max-height: 148px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: -77px;
    left: 14px;
    background: #dee2e6;
    img, .avatar-placeholder {
      border: 4px solid #F1F2F6;
      border-radius: 50%;
      box-shadow: 0 30px 30px 0 #5b5b5b1a;
      height: 148px;
      width: 148px;
      object-fit: cover;
      cursor: pointer;
    }
    .avatar-placeholder {
      background-image: url('/assets/img/avatar-icon.svg');
      // background-image: url('https://master.d3rt3lrr0t9gt0.amplifyapp.com/static/docs/avatar-icon.e4281fca.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center 20px;
    }

    &.border-white {
        border: 4px solid $white-color;
    }
    &.border-gray {
        border: 4px solid $card-muted-color;
    }
    @media screen and (max-width: 991px) {
      max-width: 88px;
      max-height: 88px;
      top: -37px;
      img, .avatar-placeholder {
        width: 88px;
        height: 88px;
        background-position: center 11px;
      }
    }
  }

}
