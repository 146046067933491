.layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
}

.pill {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  padding-bottom: 3rem;
  border-radius: 0.25rem;
  border-width: 1px;
  background-color: #ffffff;
  border-style: solid;
  border-color: #e5e7eb;
  text-align: center;
}

.logo {
  margin-bottom: 1rem;
  margin-top: 2rem;
  width: 11rem;
  height: auto;
}
.login-title {
  margin-bottom: 1.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.login-text {
  margin-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.login-btn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 600;
    border-radius: 0.25rem;
    background: white;
    border-color: #e5e7eb;
    border-style: solid;
    padding: 0.75rem;
    gap: .5rem;
    align-items: center;
    justify-content: center;
    border-width: 1px;
}
.w-6 {
  width: 1.5rem;
}
