.img-rounded{
    border-radius: $border-radius-extreme;
    transition: opacity 0.5s ease 0s;
    max-width: 100%;
}
.img-details{
    min-height: 50px;
    padding: 0 4px 0.5em;

}
.img-details img{
    width: 50px;
}
.img-details .author{
    margin-left: 10px;
    margin-top: -21px;
    width: 40px;
}
.img-circle{
    background-color: $white-color;
    margin-bottom: 10px;
    padding: 4px;
    border-radius: 50% !important;
    max-width: 100%;
}
.img-thumbnail{
    border: 0 none;
    border-radius: $border-radius-extreme;
    box-shadow: 0 1px 2px rgba(164, 158, 147, 0.6);
    margin-bottom: 10px;
}
.img-no-padding{
    padding: 0px;
}
.example-page .img-rounded{
    margin: 50px 0 20px;
}
.img-shadow{
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
                0 3px 14px 2px rgba(0, 0, 0, 0.12),
                0 5px 5px -3px rgba(0, 0, 0, 0.2)
}
.images-title{
    margin-bottom: 20px;
    height: 50px;
}
.nav-link .profile-photo-small{
    width: 40px;
    height: 30px;
    margin: -10px 0 0 -15px;
}
.profile-picture{
    margin: 0 auto;
    .fileinput-new {
        margin: auto;
        display: block;


        img, .avatar-placeholder {
            border: 4px solid #FFF;
            border-radius: 50%;
            box-shadow: 0 30px 30px 0 #5b5b5b1a;
            width: 200px;
            height: 200px;
            margin: auto;
          }
          .avatar-placeholder {
            background-image: url('/assets/img/avatar-icon.svg');
            background-image: url('https://master.d3rt3lrr0t9gt0.amplifyapp.com/static/docs/avatar-icon.e4281fca.svg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center 20px;
          }
    }
    .fileinput-exists img{
        width: 200px;
        height: 200px;
        border-radius: 50%;
        margin-bottom: 10px;
        border: 5px solid #FFF;
        box-shadow: 0px 9px 15px 0px #c7c4c4;
    }
}
