.modal-header {
    border-bottom: 1px solid $medium-gray;
    padding: 20px;
    text-align: center;
    display: block !important;

    &.no-border-header{
        border-bottom: 0 none !important;
        & .modal-title{
            margin-top: 20px;
            font-size: 18px;
            color: #333;
        }
    }
    button.close{
      &:focus {
        outline: none;
      }
    }
}
.modal-dialog{
    &.modal-sm,
    &.modal-register{
        .modal-header{
            button.close{
                margin-top: 0;
            }
        }
    }
    &.profile-page {
        max-width: 1000px;
        .inst-profile-page {
            justify-content: space-around;
            .name {
                .title {
                    color: #771b1c;
                }
            }
        }
        .card-profile {
            &_location {
                text-align: center;
                margin-bottom: 10px;
                color: #000;
                display: flex;
                justify-content: center;
                &_icon {
                    height: 16px;
                    margin-right: 5px;
                    margin-top: 2px;
                    background-image: url('https://d3tz2xd2453h51.cloudfront.net/location-icon.svg');
                    width: 22px;
                    background-repeat: no-repeat;
                    display: none;
                }
            }
            &_website {
                font-weight: 500;
                text-align: center;
                a {
                    font-weight: 500;
                    color: #771b1c;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .basic-info {
            margin: 15px 0;
            padding: 0 15px;
            .info-label {
                font-size: 18px;
                @media screen and (min-width: 991px) {
                    font-size: 24px;
                }
            }
            .info-item {
                font-size: 14px !important;
                border-color: #771b1c !important;
            }
        }
        .button-container {
            margin-top: 30px;
            .btn {
                height: 50px;
                font-size: 16px;
                background: #771b1c !important;
                border: none;
            }
        }
        .bio-panel {
            font-size: 14px;
        }
        .lesson-price-panel, .bio-panel {
            &_row {
                align-items: flex-start;
            }
            &_price {
                color: #771b1c !important;
            }
            .panel_bullet-item {
                &::before {
                    background: #771b1c;
                    margin-top: 2px;
                    
                }
            }
            @media screen and (max-width: 991px) {
                margin: auto !important;
                padding: 0;
            }

            @media screen and (min-width: 991px) {
                min-width: 500px;
                margin-left: 0 !important;
            }
        }
    }
}

.modal-content {
    border: 0 none;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15), 0 0 1px 1px rgba(0, 0, 0, 0.1);
    .modal-header{
        h6{
            margin-top: 10px;
        }
    }
}

.modal-dialog {
    padding-top: 60px;
    .row {
        overflow: visible;
    }
}
.modal-body{
    padding: 20px 50px;
    color: #000;
    @media (max-width: 767px) {
        padding: 20px 30px;
        &.reg-modal {
            padding: 50px 27px;
        }
    }
    .terms-modal {
        overflow: scroll;
        max-height: 600px;
        border: 1px solid #000;
        padding: 10px;
        p {
            font-weight: 500;
        }
    }
    &#schedule-modal-body {
        position: relative;
        .schedule-legend {
            position: absolute;
            height: 50px;
            width: 115px;
            top: -65px;
            right: 89px;
            background: #fff;
            z-index: 999;
            border: 1px solid #ddd;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                position: relative;
                padding-left: 25px;
                &::before {
                    content: ' ';
                    position: absolute;
                    background: #3788d8;
                    height: 15px;
                    width: 15px;
                    border-radius: 50%;
                    left: 3px;
                    top: 3px;
                }
            }
        }

        @media screen and (max-width: 582px) { 
            .schedule-legend {
                margin: -33px auto 10px;
                font-size: 14px;
                position: unset;
                height: 35px;
                width: 100px;
                p {
                    font-size: 13px;
                }
            }
        }

    }
}
.modal-footer {
    border-top: 1px solid $medium-gray;
    padding: 0px;

    &.no-border-footer{
        border-top: 0 none;
    }
}
.modal-footer .left-side, .modal-footer .right-side{
    display: inline-block;
    text-align: center;
    width: 49%;
}
.modal-footer .btn-link{
    padding: 20px;
    width: 100%
}
.modal-footer .divider{
    background-color: $medium-gray;
    display: inline-block;
    float: inherit;
    height: 63px;
    margin: 0px -3px;
    // position: absolute;
    width: 1px;
}

.modal-register .modal-footer{
    text-align: center;
    margin-bottom: 25px;
    padding: 20px 0 15px;
    span{
        width: 100%;
    }
}
.modal-register .modal-content {
    border: 2px solid #771b1c;
}
.modal-header:after {
     display: table;
     content: " ";
}
.modal-header:before{
    display: table;
    content: " ";
}
